




























import { defineComponent } from '@nuxtjs/composition-api';
import type { MetaInfo } from 'vue-meta';
import Icon from '../../almarwan/components/Icon.vue';

export default defineComponent({
  name: 'CustomBreadcrumbs',
  components: {
    Icon,
  },
  props: {
    /**
     * The values of the breadcrumbs
     * {
     *   text: string, // the text of breadcrumb
     *   link: String, // the redirect link
     *   icon?: String, // the prefix icon of the breadcrumb only pass the name if needed
     * }
     */
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    /**
     * The separator of the breadcrumbs
     * @values / is (the default), :, >, arrow
     */
    separator: {
      type: String as () => '/' | ':' | '>' | 'arrow',
      default: '/',
    },
    /**
     * the home icon
     * passing it will show an icon next to home breadcrumb
     */
    homeIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      breadcrumbListSchema: null,
      breadcrumbsResult: [{}] as [
        { text: string; link: string; icon?: string }
      ],
    };
  },
  async created() {
    await this.getBreadcrumbs();

    const breadcrumbListItemListElement = [];
    const arPage = this.$i18n.locale === 'ar' ? '/ar' : '';
    for (const [i, item] of this.breadcrumbsResult.entries()) {
      const link = item.link.replace('/ar', '');
      if (this.breadcrumbsResult.length <= i + 1) {
        // this is spacial case we have in product page
        breadcrumbListItemListElement.push({
          '@type': 'ListItem',
          position: i + 1,
          item: {
            '@id': `https://almarwan.com${this.$route.path}`,
            name: item.text,
          },
        });
      } else {
        breadcrumbListItemListElement.push({
          '@type': 'ListItem',
          position: i + 1,
          item: {
            '@id': `https://almarwan.com${arPage}${link}`,
            name: item.text,
          },
        });
      }
    }

    this.breadcrumbListSchema = {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbListItemListElement,
    };
  },
  methods: {
    getBreadcrumbs() {
      this.breadcrumbsResult = [
        {
          text: this.$t('Home').toString(),
          link: '/',
          icon: this.homeIcon ? 'home' : null,
        },
      ];

      if (this.breadcrumbs && this.breadcrumbs.length > 0) {
        for (let i = 0; i < this.breadcrumbs.length; i++) {
          const item = this.breadcrumbs[i] as {
            text: string;
            link: string;
            icon: string;
          };
          if (item) {
            this.breadcrumbsResult.push({
              text: this.$t(item.text).toString(),
              link: item.link,
              icon: item.icon,
            });
          }
        }
      }
    },
    separatorContent() {
      switch (this.separator) {
        case ':':
          return 'colon-content';
        case '>':
          return 'chevron-content';
        case 'arrow':
          return 'arrow-content';
        default:
          return 'backslash-content';
      }
    },
  },
  head(): MetaInfo {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: this.breadcrumbListSchema,
        },
      ],
    };
  },
});
