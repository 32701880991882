




































































import { defineComponent } from '@vue/composition-api'
import CustomBreadcrumbs from '../../../components/custom/CBreadcrumbs.vue'
import LatestNews from '../../../almarwan/views/blog/components/LatestNews.vue'
import SeoInfo from '../../../modules/catalog/product/components/SeoInfo.vue';
import type { MetaInfo } from 'vue-meta';

export default defineComponent({
  components: {
    LatestNews,
    OurLocation: () => import('../../../almarwan/components/OurLocation.vue'),
    NavBanner: () => import('../../../components/custom/CNavBanner.vue'),
    NavSection: () => import('../../../components/custom/CNavSection.vue'),
    NavBlackTile: () => import('../../../components/custom/CNavBlackTile.vue'),
    CustomAccordion: () => import('../../../components/custom/CAccordion.vue'),
    CustomHeading: () => import('../../../components/custom/CDashedTitle.vue'),
    AccordionHeading: () => import('../../../components/custom/CAccordionHeading.vue'),
    TextSection: () => import('../../../components/custom/CTextSection.vue'),
    TwoColTextSection: () => import('../../../components/custom/C2ColTextSection.vue'),
    OurCustomers: () => import('../../../almarwan/components/OurCustomers.vue'),
    CustomBreadcrumbs,
    SeoInfo
  },
  data() {
    return {
      pageTitle: 'Machinery Rentals | Al Marwan Heavy Machinery',
      breadcrumbs : [
        { text: this.$i18n.t('Services'), link: this.localePath('/services')},
        { text: this.$i18n.t('Rentals'), link: this.localePath('/services/machinery-rentals') }
      ],
     // desc1_ar: this.$i18n.t("Al Marwan is an award-winning heavy equipment rental company in the GCC for two consecutive years with an expansive fleet of over 3,000 heavy machinery, all maintained to the highest standards, supporting construction projects throughout the UAE, Saudi Arabia, and Oman."),
      desc1: this.$i18n.locale=='ar'? 
              this.$i18n.t("Al Marwan is an award-winning heavy equipment rental company in the GCC for two consecutive years with an expansive fleet of over 3,000 heavy machinery, all maintained to the highest standards, supporting construction projects throughout the UAE, Saudi Arabia, and Oman.") 
              :"Al Marwan is an <a href='https://meconstructionnews.com/57456/revealed-the-winners-of-the-construction-machinery-awards-2023' target='_blank'> award-winning heavy equipment rental company</a> in the GCC for two consecutive years with an expansive fleet of over 3,000 heavy machinery, all maintained to the highest standards, supporting construction projects throughout the UAE, Saudi Arabia, and Oman.",

      desc1_1: this.$i18n.t('With 45+ years of experience under our hard hats, we ensure our clients receive well-maintained, young equipment capable of smoothly completing projects. Our construction equipment rental services gets you supported by a team of expert machinery operators and mobile workshops.'),
      desc2: this.$i18n.locale=='ar' ? 
              "تُلبي معداتنا الثقيلة المعروضة للآجار جميع أنواع مشاريع البناء في مختلف الصناعات؛ مثل تطوير بُنى تحتية على نطاق واسع، تعمير المباني السكنية، أو المشاريع الصناعية الضخمة كالتعدين، حيث نحرص على تأجير مجموعة متنوعة من معدات البناء المفحوصة بشكل تام لضمان أداء عالي طيلة فترة التشغيل، والملبية لاحتياجات المشاريع المختلفة مثل معدات تحريك التربة القوية كالبلدوزرات و<a href='/ar/c/equipment-rent/loaders/wheel-loaders'>اللودرات ذات العجلات</a> إضافةً إلى <a href='/ar/c/equipment-rent?catalog=816&q=1718100917585'> الشاحنات</a> القلابة والرافعات المجنزرة و<a href='/ar/c/equipment-rent/excavators?types=777&q=1718101036464'>حفارات الذراع الطويل </a> المعدلة والتي يصل طولها إلى 28 متر والمصنعة في ورشات شركة المروان."
              : "Our extensive fleet of heavy equipment is designed to cater to all kinds of construction projects across various industries. Whether you are working on large-scale infrastructure developments, residential builds, or industrial projects, we have the machinery to meet your needs. From powerful earthmoving equipment such as bulldozers, wheel loaders and dump trucks, crawler cranes, and specialized <a href='/c/equipment-rent/excavators/long-boom-excavators' >long-boom excavators</a> manufactured in-house up to 28 meters. We ensure every rental equipment is maintained to the highest standards for optimal performance and reliability.",
      desc3: this.$i18n.t("Discover a wide range of construction equipment for sale at Al Marwan, your trusted source for new heavy equipment in the GCC. As an authorized distributor of heavy equipment for top brands like Kobelco, Powerscreen, Dynapac, ABI, Toku, Jisung, Simex, and CIFA, we provide robust options to meet your project needs."),
      desc4: this.$i18n.t("Al Marwan's rental fleet is one of the largest and most diverse fleets in the GCC, serving construction projects with heavy equipment since 1978."),
      desc4_1: this.$i18n.t("Al Marwan boasts an impressive lineup of heavy equipment from the industry's most trusted brands, including Volvo, CAT, Kobelco, Komatsu, Hyundai, and Hitachi, ensuring reliability and top-notch performance."),
      desc4_2: this.$i18n.locale === 'ar' ? 
                "كمفولفوا يتميز الأسطول بتنوعه لخدمة كافة أنواع المشاريع  مثل المعدات الثقيلة المخصصة لمهام الهدم المختلفة <a href='/ar/c/equipment-rent/loaders/wheel-loaders?condition=620&q=1718366270446'>كاللوادر ذات العجلات المتوسطة والكبيرة</a> التي يصل وزنها إلى أكثر من 160 طن، ومجموعة البلدوزرات التي يتراوح وزنها من 40 إلى 108 طن مثل بلدوزر كات D8T، وD11 وكوماتسو D475A، إضافةً إلى تأجير مجموعة من الحفارات الصغيرة والكبيرة والحفارات المتخصصة ذات الذراع الطويل لخدمة مواقع التعدين والمحاجر والتجميع، كما تتواجد <a href='/ar/p/RNT017/large-articulated-dump-trucks-40-ton.html'>شاحنات فولفو</a> ا<a href='/ar/p/RNT017/large-articulated-dump-trucks-40-ton.html'>لقلابة من طراز A40G</a> المختصة بمهام نقل المواد على نطاق واسع."
                : "Our rental fleet includes heavy-duty equipment for various tasks such as <a href='/c/equipment-rent/loaders/wheel-loaders'>medium and large wheel loaders</a> weighing up to 160+ tons, and bulldozers from 40 up to 108 tons including popular models like the CAT D8T, Komatsu D475A, and CAT D11 dozers. For mining, quarry, and aggregate operations, we offer large excavators for rent alongside specialized long boom excavators. Not to mention <a href='/p/RNT017/large-articulated-dump-trucks-40-ton.html' >Volvo’s largest A40G dump truck rental fleet</a> in the entire GCC, ready to efficiently handle heavy and extensive materials.",
      desc5: this.$i18n.t("Each of our heavy machinery rentals includes a highly trained, HSE, and OSHAD-certified operator, guaranteeing the highest standards of safety and performance. Our heavy machinery operators possess 45+ years of contracting experience, adhering to stringent safe work procedures, while maintaining a focus on precision and time efficiency, proving competence for complex excavation or delicate maneuvers."),
      desc5_1: this.$i18n.t("With its extensive experience, Al Marwan has successfully completed over 32,000 projects across the UAE in various industries, including sea dredging, quarry and aggregate, marine works, demolition, and more, spanning Dubai, Abu Dhabi, and Sharjah, as well as significant contributions to construction projects in Oman and Saudi Arabia."),
      accordionDataCol1 : [
        {
          summary: this.$i18n.t('Customized Machinery Solutions'),
          details: this.$i18n.t('We provide specialized machinery to accommodate the diverse demands of any construction project. Be it large-scale projects like marine and construction, or smaller indoor tasks, our rental equipment can be customized with the right attachments, such as jackhammers and rock breakers for quarries and demolition jobs.'),
          // icon: 'home',
        },
        {
          summary: this.$i18n.t('Well-trained certified heavy equipment operators'),
          details:
          this.$i18n.t("Each of our heavy machinery rentals comes with a highly trained HSE and OSHAD-certified operator, ensuring the highest standards of safety and efficiency. Every certified operator has an extensive background in contracting, which guarantees that they can handle all types of heavy equipment with minimum downtime."),
        },
        {
          summary: this.$i18n.t('Experts in customized long-boom excavator rentals'),
          details:
          this.$i18n.t("Al Marwan is renowned for its expertise in customizing long-boom excavators, owning the largest CAT 395 long-boom excavator and SK850 excavator in the Middle East that feature long-reach booms of up to 28 meters, crafted in-house."),
        },
        {
          summary: this.$i18n.t('Heavy equipment telematics and monitoring systems'),
          details: this.$i18n.t("With machinery rentals, we provide one of our 35+ mobile workshops to stay on standby directly on site. These mobile service workshops are available for rapid response to any breakdowns, ensuring minimal downtime."),
        },
        {
          summary: this.$i18n.t('Comprehensive fleet of heavy machinery'),
          details: this.$i18n.t("Our fleet is home to various heavy-duty construction equipment including 40 to 110-ton bulldozers, a wide range of excavators from 1-ton minis to 160-ton excavators, in addition to large dump trucks, rollers, wheel loaders, asphalt pavers, and crawler cranes.")
        },
      ],
      accordionDataCol2 : [
        {
          summary: this.$i18n.t('Regular heavy machinery maintenance'),
          details: this.$i18n.t('Al Marwan runs one of the largest machinery workshops across the UAE, Saudi Arabia, and Oman. This cutting-edge machinery workshop is where our expert technicians work their magic to maintain the optimal performance of all our heavy equipment, and keep all heavy machinery in top shape so your project can run smoothly.'),
          // icon: 'home',
        },
        {
          summary: this.$i18n.t('4 decades of completing projects with rental equipment'),
          details: this.$i18n.t("With over 45 years of experience in heavy machinery rentals and more than 3,500 completed projects, Al Marwan has never once failed to provide heavy equipment solutions tailored to your specific project needs."),
        },
        {
          summary: this.$i18n.t('Trusted heavy machinery brands for instant rent'),
          details: this.$i18n.t("Our inventory includes top-tier equipment from renowned names such as Volvo, CAT, Kobelco, Komatsu, Hyundai, and Hitachi. This extensive selection ensures that no matter the scope or scale of your project, you can find the perfect machinery to get the job done efficiently."),
        },
        {
          summary: this.$i18n.t("Al Marwan's presence across the GCC"),
          details: this.$i18n.t("Clients with construction projects across the GCC can find machinery tailored to their specific needs, enhancing machines' capabilities with our range of equipment across all categories and sizes."),
        },
        {
          summary: this.$i18n.t('Mobile workshops for prompt response'),
          details: this.$i18n.t("Al Marwan offers 35+ mobilized maintenance services to ensure optimal performance of all equipment. Our experienced technicians provide quick and efficient breakdown and emergency response services on-site, ensuring minimal disruption to your projects."),
        },
      ],
      infoData: `<div>
        <h2>Heavy Machinery Rental</h2>
        <p>
          <a href='/'>Al Marwan Machinery</a> offers a comprehensive range of heavy machinery for rent in the United Arab Emirates and the Arab Gulf countries. Our fleet includes more than 3,000 units of specialized heavy machinery that cater to various sectors such as construction, industry, mining, and quarrying.
        </p>
        <h2>Key Features:</h2>
        <ul>
          <li>Extensive fleet: Over 3,000 units of specialised heavy machinery ready to serve projects in the UAE, Saudi, and Oman.</li>
          <li>Global brands: Includes notable heavy machinery from trusted brands like Volvo, CAT, Kobelco, Komatsu, Hyundai, and Hitachi.</li>
        </ul>
        <h2>Types of Equipment:</h2>
        <ul>
          <li>Bulldozers:</li>
          <ul>
            <li>40 ton bulldozers, including models like the CAT D8T</li>
            <li>Up to 110-ton bulldozers are available for rent, including models like the CAT D11 and D475A</li>
          </ul>
          <li>Excavators:</li>
          <ul>
            <li>200-ton large mining excavators for rent, including the PC2000</li>
            <li>125-ton excavators like the PC1250</li>
            <li>CAT, Volvo, and Komatsu excavators, including models like the CAT 395, <a href="/p/RNT077/large-excavators-55-ton-standard-boom.html">Volvo EC550E</a>, and PC450, including long boom excavator models</li>
            <li>Custom-built long-reach excavators from 20 to 80 tons</li>
            <li>80-ton Kobelco excavators with a 26 m long-reach boom</li>
          </ul>
          <li>Dump trucks:</li>
          <ul>
            <li>Largest fleet of <a href='https://www.volvoce.com/middle-east/en-sa/products/articulated-haulers/a40g/' target=_blank>Volvo A40G</a> dump trucks in the GCC</li>
            <li>45-ton dumpers like the A45G models.</li>
          </ul>
          <li>Wheel loaders:</li>
          <ul>
            <li>Large <a href="/p/RNT027/large-wheel-loaders-116-ton.html">wheel loaders of 116 tons</a>, like the Komatsu WA900.</li>
            <li>Medium <a href="/p/RNT030/medium-wheel-loaders-34-ton.html">wheel loaders of 34 tons</a></li>
            <li>Medium wheel loaders of 23 tons</li>
          </ul>
          <li>Crawler cranes:</li>
          <ul>
            <li><a href='/c/equipment-rent?catalog=820&types=788&q=1718362168999'>Crawler cranes for rent</a> from 60 up to 250 and 600 tons</li>
            <li>Popular models include the CK1350 7250005 and CKS2500.</li>
            <li><a href="/c/equipment-rent/cranes/mobile-cranes">Mobile cranes</a> and material handling equipment of 25, 60, and 75 tons</li>
          </ul>
        </ul>
        <h2>Industries Served:</h2>
        <ul>
          <li>Construction</li>
          <li>General contracting</li>
          <li>Infrastructure</li>
          <li>Mining</li>
          <li>Quarry and aggregate</li>
          <li>Municipal projects & maintenance</li>
          <li>Oil and gas</li>
        </ul>
        <h2>Specialized Fleet:</h2>
        <ul>
          <li>Volvo: Largest dump truck customer in the GCC, including wheel loaders, excavators, articulated dump trucks, asphalt pavers, rollers, prime movers, and diesel tankers.</li>
          <li>CAT: Extensive fleet of D6T dozers, CAT 395 excavators, and one of the world's largest dozers, the CAT D11.</li>
          <li>Kobelco: Largest fleet in the GCC, featuring excavators and crawler cranes.</li>
        </ul>
        <p>
          Renting heavy equipment from Al Marwan includes certified operators with HSE Safety and OSHAD certification, ensuring maximum safety and efficiency. Our rental services feature real-time telematics and on-site maintenance, guaranteeing minimal downtime and optimal performance. Rent your heavy equipment from us for all your project needs.
        </p>
        <h2>Key Projects Served in GCC:</h2>
        <ul>
          <li>Khor Fakkan road construction</li>
          <p>Al Marwan played a crucial role in the AED 5.8 billion <a href="/news/88/Al-Marwan-Case-Study-Blog-1-What-Does-It-Take-to-Build-an-89-km-Road-through-the-Mountain">Khor Fakkan road project</a> in Sharjah. We ensured continuous 24/7 operation through rotating shifts. Our advanced telematics monitored and prevented equipment failures, supported by mobile workshops for on-site maintenance. Our commitment to safety resulted in zero injuries, accidents, and downtime, significantly expediting project completion.</p>
          <li>UAE flood relief and recovery</li>
          <p>In response to the <a href="https://www.khaleejtimes.com/uae/uae-from-providing-free-services-to-helping-rebuild-roads-businesses-help-flood-relief" target=_blank>unprecedented flooding across the UAE in 2024</a>, Al Marwan mobilized resources to aid in relief efforts. Our team helped clear streets, move stranded vehicles, and expedite drying. Additionally, we rebuilt a 1 km stretch of road near Al Batayeh within 24 hours, providing crucial support to the community.</p>
          <li>Earthmoving fleet for Saudi's NEOM megaproject</li>
          <p>A fleet of Volvo A40G articulated dump trucks and CAT D8T dozers, including the Middle East's first D11, were deployed on-site in Saudi Arabia. These machines were critical in moving mountains and earth for The Line master plan within the <a href="https://medium.com/@almarwan/rental-machinery-fleet-of-al-marwan-leads-the-way-forward-50951106a30f" target=_blank>NEOM</a> project.</p>
          <li>Marine works and harbour excavation in Dibba, Oman</li>
          <p>A fleet of heavy equipment, including earthmoving and material handling machines such as 21 Volvo A40G dump trucks, 4 Komatsu long-reach excavators, and 135-ton KOBELCO crawler cranes, worked on Sarooj's rock excavation and harbour preparation project.</p>
          <li>Large mining equipment rental for UAE's largest quarry in RAK</li>
          <p>The large 120-ton Komatsu PC1250 mining excavator was hired to work on Stevin Rock's quarry, alongside 3 Volvo A40G articulated dump trucks, 9 PC400 excavators, and a mobile screener to streamline the extraction, mobilization, and sorting process.</p>
        </ul>
        <h2>Al Marwan Machinery Wins GCC Equipment Rental Company of 2023 & 2024</h2>
        <p>Al Marwan Machinery, which has been fulfilling the world’s demand for heavy machinery for 45 years, was named <a href="/c/equipment-rent">GCC equipment rental company of the year</a> for the 2nd consecutive year at <a href="https://cmmeawards.com/" target="_blank">CMME awards</a>. Organized by <a href="https://www.cpitrademedia.com/" target="_blank">CPI trade media</a> and presented by <a href="https://meconstructionnews.com/" target="_blank">Construction Machinery ME</a> and <a href="https://constructionmachinerymenews.com/" target="_blank">ME Construction News</a>, the event honored excellence in the Middle East's heavy machinery sector, highlighting the company’s dedication to top-quality equipment and services.</p>
        <h2>Benefits of Renting Heavy Equipment</h2>
        <p>Renting heavy equipment from Al Marwan offers numerous advantages:</p>
        <ul>
          <li><span>Cost-effective:</span> No down payment, lower monthly payments, and reduced liability.</li>
          <li><span>Latest technology:</span> Access to the latest equipment models.</li>
          <li><span>Logistics and storage:</span> No storage required, and transportation is handled by us.</li>
          <li><span>Avoidance of depreciation and insurance costs:</span> Save on depreciation and insurance.</li>
        </ul>
        <h2>Equipment Rental Process</h2>
        <p>Renting heavy equipment from Al Marwan is straightforward. Select the right equipment from our user-friendly online catalogue, which allows you to browse and filter options by category, size, and specialty. Once you express interest, a rental operations representative will contact you to finalize the details.</p>
        <h2>Why Rent from Al Marwan?</h2>
        <ul>
          <li><span>Well-maintained machinery:</span> Ensures smooth project completion.</li>
          <li><span>Expert operators:</span> Skilled professionals to handle complex tasks.</li>
          <li><span>Mobile workshops:</span> On-site maintenance for minimal downtime.</li>
          <li><span>Diverse fleet:</span> Suitable for various project needs in different industries.</li>
        </ul>
        <p>Rent your heavy equipment from Al Marwan and benefit from our extensive fleet, expert operators, and dedicated support, ensuring your project's success.</p>
      </div>
      `,
      infoData_ar: `<div>
        <h2>معدات ثقيلة للإيجار</h2>
        <p>
          تقدم شركة المروان لبيع وتأجير المعدات الثقيلة مجموعة شاملة من المكنات الثقيلة للإيجار في دولة الإمارات ودول الخليج العربي بشكل عام، يضم أسطولنا أكثر من 3,000 وحدة من المكنات الثقيلة المتخصصة والتي تُلبي احتياجات قطاعات متنوعة مثل البناء والصناعة والتعدين والمحاجر.
        </p>
        <h2>الميزات الرئيسية:</h2>
        <ul>
          <li>أسطول ضخم: أكثر من 3,000 وحدة من المعدات الثقيلة المُتخصصة جاهزة لخدمة المشاريع في الإمارات والسعودية وعمان.</li>
          <li>علامات تجارية عالمية: تتضمن مكنات من علامات تجارية موثوقة مثل فولفو، كاتربيلر، كوبلكو، كوماتسو، هيونداي، وهيتاشي</li>
        </ul>
        <h2>أنواع المعدات:</h2>
        <ul>
          <li>البلدوزرات:</li>
          <ul>
            <li>بلدوزرات بوزن 40 طن، مثل CAT D8T</li>
            <li>بلدوزرات حتى 110 طن متاحة للإيجار، مثل CAT D11 و D475A</li>
          </ul>
          <li>الحفارات:</li>
          <ul>
            <li>حفارات تعدين كبيرة بوزن 200 طن للإيجار، بما في ذلك الحفارة من نوع PC2000</li>
            <li>حفارات بوزن 125 طن مثل PC1250</li>
            <li>حفارات من العلامات التجارية كاتربيلر وفولفو وكوماتسو وموديلات مُختلفة تشمل CAT 395و<a href="/ar/p/RNT077/large-excavators-55-ton-standard-boom.html"> Volvo EC550E</a> وPC450، بالإضافة إلى الحفارات بذراع طويلة.</li>
            <li>حفارات طويلة الذراع مخصصة بوزن من 20 إلى 80 طن</li>
            <li>حفارات كوبلكو بوزن 80 طن مع ذراع طويلة بطول 26 متر</li>
          </ul>
          <li>شاحنات قلابة:</li>
          <ul>
            <li><a href="/ar/p/RNT017/large-articulated-dump-trucks-40-ton.html">أكبر أسطول من الشاحنات القلابة Volvo A40G</a> في الخليج العربي.</li>
            <li>شاحنات قلابة بوزن 45 طن، مثل موديلات A45G.</li>
          </ul>
          <li>لوادر بعجلات:</li>
          <ul>
            <li><<a href="/ar/p/RNT027/large-wheel-loaders-116-ton.html">لوادر كبيرة بوزن 116 طن</a>> مثل Komatsu WA900</li>
            <li><a href="/ar/p/RNT030/medium-wheel-loaders-34-ton.html">لوادر متوسطة بوزن 34 طن</a></li>
            <li>لوادر متوسطة بوزن 23 طن</li>
          </ul>
          <li>رافعات مجنزرة:</li>
          <ul>
            <li><a href="/ar/c/equipment-rent/cranes/crawler-cranes">رافعات مجنزة للإيجار</a> بوزن من 60 إلى 250</li>
            <li>موديلات شهيرة تشمل CK1350 ،72500S وCKS2500 من كوبلكو</li>
            <li><a href="/ar/c/equipment-rent/cranes/mobile-cranes">رافعات متحركة ومعدات مناولة</a> بوزن 25، 60، و75 طن</li>
          </ul>
        </ul>
        <h2>الاستخدامات:</h2>
        <ul>
          <li>البناء</li>
          <li>التعدين</li>
          <li>المحاجر والمقالع</li>
          <li>النفط والغاز</li>
        </ul>
        <h2>الاستخدامات:</h2>
        <li>البناء</li>
        <li>التعدين</li>
        <li>المحاجر والمقالع</li>
        <li>النفط والغاز</li>
        <h2>أسطول متخصص:</h2>
        <ul>
          <li>فولفو: أكبر عميل للشاحنات القلابة في الخليج، بما في ذلك اللوادر ذات العجلات، الحفارات، شاحنات التفريغ المفصلية، ماكينات رصف الأسفلت، البكرات، الجرارات، وصهاريج الديزل.</li>
          <li>كاتربيلر: أسطول كبير من الجرافات D8T، حفارات CAT 395، وأحد أكبر الجرافات في العالم CAT D11.</li>
          <li>كوبلكو: أكبر أسطول في الخليج العربي يشمل الحفارات والرافعات المجنزرة.</li>
        </ul>
        <p>
          تأجير المعدات الثقيلة من المروان يشمل مشغلين معتمدين بشهادات HSE وOSHAD لضمان أقصى درجات الأمان والكفاءة. خدمات الإيجار لدينا تتضمن أنظمة خدمات المراقبة عن بعد بشكل مُباشر والصيانة في الموقع،  مما يضمن تقليل وقت التوقف وتحقيق أداء مثالي.
        </p>
        <h2>حلول تأجير للمشاريع الرئيسية</h2>
        <ul>
          <li>مشروع بناء طريق خورفكان</li>
          <p>لعبت شركة المروان دورًا حاسمًا في مشروع طريق خورفكان بتكلفة 5.8 مليار درهم في الشارقة، اعتمدنا مبدأ العمل المستمر على مدار الساعة بفضل نظام النوبات. ساهمت أنظمة المراقبة المتقدمة لدينا في تجنب أعطال المعدات، وتم دعمها بورش متنقلة للصيانة الفورية في الموقع. التزمنا بمعايير الأمان لتجنب وقوع إصابات أو حوادث أو فترات تعطل، الأمر الذي ساهم في تسريع إنجاز المشروع.</p>
          <li>الإغاثة من الفيضانات في الإمارات</li>
          <p>استجابةً للفيضانات غير المسبوقة التي شهدتها الإمارات في عام 2024، قامت شركة المروان بتجهيز الموارد للمساعدة في جهود الإغاثة. ساعد فريقنا في تنظيف الشوارع ونقل المركبات العالقة وتسريع عملية التجفيف. بالإضافة إلى ذلك، قُمنا بإعادة بناء طريق بطول 1 كيلومتر بالقرب من مدينة البطائح في غضون 24 ساعة، مقدمين دعمًا حيويًا للمجتمع.</p>
          <p>للفيضانات غير المسبوقة</p>
          <li>أسطول ضخم من مكنات جرف التربة لمشروع نيوم الضخم في السعودية</li>
          <p>شاركت المروان في مشروع حفر الصخور وإعداد الميناء لصالح شركة سروج، ساهم في المشروع أسطول من المعدات الثقيلة، بما في ذلك مكنات جرف التربة ومعدات مناولة المواد مثل 21 شاحنة تفريغ Volvo A40G، و4 حفارات طويلة الذراع من كوماتسو، ورافعات زاحفة من كوبلكو بوزن 135 طن</p>
          <li>تأجير معدات تعدين ضخمة لأكبر موقع تحجير في رأس الخيمة</li>
          <p>شاركت المروان في مشروع حفر الصخور وإعداد الميناء لصالح شركة سروج، ساهم في المشروع أسطول من المعدات الثقيلة، بما في ذلك مكنات جرف التربة ومعدات مناولة المواد مثل 21 شاحنة تفريغ Volvo A40G، و4 حفارات طويلة الذراع من كوماتسو، ورافعات زاحفة من كوبلكو بوزن 135 طن</p>
          <li>تأجير معدات تعدين ضخمة لأكبر موقع تحجير في رأس الخيمة</li>
          <p>أجّرَت المروات حفارة تعدين ضخمة من نوع كوماتسو بوزن 120 طن من طراز PC1250 للعمل في محجر Stevin Rock، إلى جانب 3 شاحنات مفصلية من نوع فولفو طراز A40G و 9 حفارات من طراز PC400، وماكينة فرز (غربال) متنقلة لتسهيل عملية الاستخراج، والتعبئة، والفرز.</p>
        </ul>
        <h2>جائزة الشركة الأولى في مجال تأجير المعدات في الخليج العربي لعام 2024</h2>
        <p>حصلت شركة المروان للمعدات الثقيلة، والتي تلبي احتياجات مشاريع المنطقة من المعدات الثقيلة منذ 45 عامًا، على جائزة شركة تأجير المعدات الثقيلة في الخليج للسنة الثانية على التوالي في جوائز CMME، حيث كرّم الحدث التميز في قطاع المعدات الثقيلة في الشرق الأوسط، مشيدًا بالتزام الشركة بتقديم معدات وخدمات عالية الجودة.</p>
        <h2>فوائد تأجير المعدات الثقيلة</h2>
        <p>يوفر تأجير المعدات الثقيلة من المروان العديد من المزايا:</p>
        <ul>
          <li><span>فعالية من حيث التكلفة:</span> لا حاجة لدفع مبلغ</li>
          <li><span>أحدث التقنيات:</span> يتوفر لدينا أحدث موديلات المعدات الثقيلة.</li>
          <li><span>الخدمات اللوجستية والتخزين:</span> لا حاجة للتخزين، سوف نتكفل بعملية النقل</li>
          <li><span>تجنب تكاليف الإهلاك والتأمين:</span> توفير في تكاليف الإهلاك والتأمين.</li>
        </ul>
        <h2>اعتبارات تأجير المعدات</h2>
        <p>رغم فوائد التأجير العديدة، يجب مراعاة ما يلي:</p>
        <ul>
          <li><span>تكاليف طويلة الأجل محتملة أعلى</span>: قد يكون التأجير المتكرر أكثر تكلفة من الشراء.</li>
          <li><span>مشكلات التوافر والتخصيص:</span> الاعتماد على المخزون المستأجر وعدم القدرة على التخصيص</li>
          <li><span>المسؤولية عن الأضرار:</span> يتحمل المستأجرون المسؤولية عن أي أضرار تحدث خلال فترة الإيجار.</li>
        </ul>
        <h2>عملية التأجير</h2>
        <p>تأجير المعدات الثقيلة من المروان عملية سهلة وبسيطة. اختر المعدات المناسبة من الكتالوج الإلكتروني، الذي يتيح لك إمكانية التصفح والتصنيف حسب الفئة، الحجم، والتخصص. بعد إبداء اهتمامك، سيتصل بك ممثل عمليات التأجير لإتمام التفاصيل.</p>
        <h2>حلول الدفع والدعم في الموقع</h2>
        <p>نقدم حلول دفع مرنة تشمل الدفع النقدي، التمويل، وخطط التقسيط. بالإضافة إلى ذلك، توفر ورشتنا المتنقلة في مواقع البناء استجابة سريعة لأي أعطال، مما يضمن أقل وقت توقف.</p>
        <h2>لماذا تستأجر من المروان؟</h2>
        <ul>
          <li><span>مكنات بحالة جيدة:</span> تضمن إتمام المشروع بسلاسة.</li>
          <li><span>مشغلون خبراء:</span> خُبراء محترفون للتعامل مع المهام المعقدة.</li>
          <li><span>ورش متنقلة:</span> صيانة في الموقع لتقليل وقت التوقف.</li>
          <li><span>أسطول متنوع:</span> مناسب لاحتياجات المشاريع المختلفة في الصناعات المختلفة.</li>
        </ul>
        <p>استأجر معداتك الثقيلة من المروان واستفد من عدد كبير من المكنات للتأجير، وخبرات مشغلينا، ودعمنا المخصص لضمان نجاح مشروعك</p>
      </div>
      `
    }
  },
  head(): MetaInfo {
    const title = this.$i18n.t('machineryRentalTitle').toString()
    const description = this.$i18n.t('machineryRentalDesc').toString()
    const url = "https://almarwan.com"+this.$route.path
    const meta = [];
    const script = []
    meta.push({ 
      property: 'title', 
      name: 'title', 
      content: title 
    })
    meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: title
    });
    meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title
    });
    meta.push({
      hid: 'twitter:text:title',
      name: 'twitter:text:title',
      content: title
    });

    meta.push({
      hid: 'url',
      name: 'url',
      content: url,
    });
    meta.push({
      hid: 'og:url',
      name: 'og:url',
      content: url,
    });
    meta.push({ 
      hid: 'og:site_name', 
      name: 'og:site_name', 
      content: title 
    });
    meta.push({ 
      hid: 'site_name', 
      name: 'site_name', 
      content: title 
    });
    meta.push({ 
      hid: 'apple-mobile-web-app-title', 
      name: 'apple-mobile-web-app-title', 
      content: title 
    });
    meta.push({ 
      hid: 'og:apple-mobile-web-app-title', 
      name: 'og:apple-mobile-web-app-title', 
      content: title 
    });

    meta.push({
      hid: 'description',
      name: 'description',
      content: description
    });
    meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: description
    });
    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description
    });
    meta.push({ 
      property: 'og:image',
      content: `${process.env.BASE_URL}favicon.ico`
    },
    { 
      property: 'twitter:image', 
      content: `${process.env.BASE_URL}favicon.ico`
    });
    script.push({
      type: 'application/ld+json',
      class: 'saswp-schema-markup-output',
      json: [
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": title,
        "url": url,
        "description": this.$i18n.t('serviceDesc').toString(),
        "publisher": {
          "@type": "Organization",
          "name": title,
          "logo": {
            "@type": "ImageObject",
            "url": `${process.env.BASE_URL}_nuxt/icons/icon_512x512.3b9037.png`,
            "width": 630,
            "height": 630
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": url
        }
      }]
    });
    return {
      title,
      meta,
      script
    };
  },
})
