


















import { computed, defineComponent, ref, useFetch } from '@nuxtjs/composition-api'
import { useContent } from '~/composables';
import Icon from '@/almarwan/components/Icon.vue';


export default defineComponent({ 
  components: {
    Icon
  },
  props: {
    infoData: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { loadBlocks } = useContent();
    const readMoreOn = ref(false);
    const catInfo = ref('');
    let blockData = null;
    // useFetch(async () => { 
    //   if (!blockData) {
    //     blockData = await loadBlocks({ identifiers: ['category_info'] });
    //     if (blockData) {
    //         catInfo.value = blockData?.[0]?.content.replace(/[\n\r]/g, '');
    //     }
    //   }
    // });
    const truncatedDescription = (description) => {
      if (description.length > 450) {
        return description.slice(0, 450);
      }
      else { 
        return description;
      }
    };
    const showMore = () => {
      readMoreOn.value = !readMoreOn.value;
      if(!readMoreOn.value) {
        scrollUp()
      }
    }
    const readMoreTxt = computed(() => (readMoreOn.value ? 'Read less' : 'Read more'));
    const scrollUp = ()  =>{
      const element = document.querySelector('.cat-info-container');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    return {
      readMoreOn,
      catInfo,
      truncatedDescription,
      readMoreTxt,
      showMore
    };
  }
}) 
